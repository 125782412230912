import axios from "axios";

const BASE_URL = "https://api.jamsolattv.com/waktusolat/v1";

export const getLocations = async () => {
    const getLocationRequest = await axios.get(
        `${BASE_URL}/location`
    )
    const data = getLocationRequest.data;
    //console.log({locations: data})
    return data;
}

export async function getPrayerTime(timeCode) {
    const code = "?code="+timeCode;
    const date = "&date="+getCurrentDate();
    const param = code +""+ date;
    const getPrayerTimesRequest = await axios.get(
        `${BASE_URL}/time${param}`
    )
    const data = getPrayerTimesRequest.data;
    //console.log({ prayer: data })
    return data;
}

function getCurrentDate() {
    const dt = new Date();
    return dt.getFullYear()+"-"+(dt.getMonth()+1)+"-"+dt.getDate();
}
